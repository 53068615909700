import gql from "graphql-tag"

/* ========== All Insights ======== */
const Insights = gql`
query GetInsights{
    newsAndInsights(sort: "Published_Date:desc") {      
        id
        Title
        URL
        Published_Date
        Service
        Sector
        insight_departments{
          id
          Name
        }
        news_and_insights_category{
            Name
        }
        Embed_Video_URL
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== Insights Filters ======== */
const InsightsFilters = gql`
query InsightsFilters{
    newsAndInsights{      
        id
        news_and_insights_category {
          Name
        }
        Service
        Sector
    }
}`

/* ========== Feature Insights ======== */
const FeatureInsights = gql`
query FeatureInsights($filter_category: String!){
    newsAndInsights(sort: "createdAt:desc", limit:7, where:{news_and_insights_category:{Name_contains:$filter_category}}) {      
        id
        Title
        URL
        Published_Date
        Service
        news_and_insights_category {
            Name
        }
        Embed_Video_URL
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== Related Insights ======== */
const RelatedInsights = gql`
query RelatedInsights($service: String!){
    newsAndInsights(sort: "createdAt:desc", limit:5, where:{Service_contains:$service}) {      
        id
        Title
        URL
        Published_Date
        Service
        news_and_insights_category {
            Name
        }
        Embed_Video_URL
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== Insight Details ======== */
const Insight_Details = gql`
query Insight_Details($slug: String!){
  newsAndInsights(where: {URL:$slug}) {
    id
    URL
    Title
    Sector
    Image {
      url
      alternativeText
    }
    imagetransforms
    ggfx_results {
      id
      content_type
      transforms
      src_import_url
      src_cftle
      field
    }
    Service
    insight_departments {
      Name
      id
    }
    Content
    Content_and_Image {
      Content_Section
      Image_Section {
        alternativeText
        url
      }
    }
    Show_Subscribe_Updates
    Accordion_Component{
      Title:Accordion_Heading
      Content:Accordion_Content
      Read_More_ID
      Image{
        alternativeText
        url
      }
    }
    imagetransforms
    ggfx_results {
      id
      content_type
      transforms
      src_import_url
      src_cftle
      field
    }
    Select_Property {
      id
      properties {
        title
        slug
        crm_id
        id
      }
    }
    Property
    Address
    news_and_insights_category {
      Name
    }
    
    Embed_Video_URL
    Highlight_Text
    Published_Date
    Client
    Author
    related_services {
      id
      Label
      Primary_URL
      Image {
        url
        alternativeText
      }
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Main_Parent {
        Primary_URL
      }
      Secondary_Menu {
        Primary_URL
      }
      nested_menus {
        Primary_URL
      }
    }
    teams {
      id
      Name
      Email_ID
      Form_Queue_ID
      URL
      Phone_Number
      Departments
      Level
      people_department{
        id
        Name
      }
      people_departments {
        id
        Name
      }
      people_services{
        id
        Name
      }
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Image {
        alternativeText
        url
      }
    }
  }
}`

/* ========== All People ======== */
// const People_Team = gql`
// query GetPeople($search_text: String!){
//     peoples(sort: "sort:asc", where:{Name_contains:$search_text}){
//         id
//         Name
//         Email_ID
//         Departments
//         Phone_Number
//         URL
//         Level
//         people_department{
//           id
//           Name
//         }
//         people_services{
//           Name
//         }
//         Image {
//           url
//           alternativeText
//         }
//         imagetransforms
//     }
//     depart_people:peoples(sort: "sort:asc", where:{Departments_contains:$search_text}){
//       id
//       Name
//       Email_ID
//       Departments
//       Phone_Number
//       URL
//       Level
//       people_department{
//         id
//         Name
//       }
//       people_services{
//         id
//         Name
//       }
//       Image {
//         url
//         alternativeText
//       }
//       imagetransforms
//     }
// }`

const People_Team = gql`
query GetPeople{
    peoples(sort: "Sort:asc"){
        id
        Name
        Email_ID
        Departments
        Phone_Number
        URL
        Level
        Form_Queue_ID
        people_department{
          id
          Name
        }
        people_departments {
          id
          Name
        }
        people_services{
          Name
        }
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== All People with Email-id ======== */
const People_Email = gql`
query GetPeopleEmail($email_id: String!){
    peoples(where:{Email_ID_contains:$email_id}){
        id
        Name
        Email_ID
        Departments
        Phone_Number
        URL
        Level
        Form_Queue_ID
        people_department{
          id
          Name
        }
        people_departments {
          id
          Name
        }
        people_services{
          id
          Name
        }
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== All People with Nego ID ======== */
const People_NegoID = gql`
query GetPeopleEmail($nego_id: String!){
    peoples(where:{Nego_Id_contains:$nego_id}){
        id
        Name
        Email_ID
        Nego_Id
        Departments
        Phone_Number
        URL
        Level
        Form_Queue_ID
        people_department{
          id
          Name
        }
        people_departments {
          id
          Name
        }
        people_services{
          id
          Name
        }
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`



/* ========== People Filter ======== */
const People_Filter = gql`
query People_Filter{
    peoples{
      id
      Departments
      Level
      Services   
      Form_Queue_ID 
      people_department{
        id
        Name
      }
      people_departments {
        id
        Name
      }
      people_services{
        id
        Name
      }
    }
}`

/* ========== Home People ======== */
const Home_People = gql`
query Home_People{
    peoples(sort: "sort:asc", limit:8){
        id
        Name
        URL
        Form_Queue_ID
        Image {
          url
          alternativeText
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
    }
}`

/* ========== People Details ======== */
const People_Details = gql`
query PeopleDetails($slug: String!){
    peoples(where: {URL:$slug}) {
        Linked_In_Link
        id
        Content
        Departments
        Email_ID
        Embed_Video_URL
        Areas_of_Expertise
        Qualification
        Name
        Phone_Number
        Level
        Services
        URL
        Form_Queue_ID
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        Image {
          url
          alternativeText
        }
        people_department{
          id
          Name
        }
        people_departments {
          id
          Name
        }
        people_services{
          id
          Name
        }

        news_and_insights {
          id
          Title
          URL
          Embed_Video_URL
          news_and_insights_category {
            Name
          }
          insight_departments {
            id
            Name
          }
          Image {
            url
            alternativeText
          }
          imagetransforms
          ggfx_results {
            id
            content_type
            transforms
            src_import_url
            src_cftle
            field
          }
        }
    }
}`

/* ========== All Offices ======== */
const GetOffices = gql`
query GetOffices{
    offices(sort: "createdAt:desc") {
      id
      Name
      URL
      Email_ID
      Phone_No
      Location
      Longitude
      Latitude
      Image {
        url
        alternativeText
      }
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
    }
}`

/* ========== Office Details ======== */
const Office_Details = gql`
query Office_Details($slug: String!){
    offices(where: {URL:$slug}) {
        id
        Name
        Email_ID
        Embed_Video_Url
        Image {
          url
          alternativeText
        }
        google_profile_link
        Latitude
        Location
        Longitude
        Content
        Opening_Hours {
          Timing
          Week_Day
        }
        Phone_No
        URL
        Video {
          alternativeText
          url
        }
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        Form_User_ID
        Form_Email_ID
        Type
        Sub_Type

        Modules{
          __typename
          ... on ComponentComponentOurPeopleComponent {
           id
           Content
           Heading
          }
          ...on ComponentComponentShowContactFormModule{
            id
            __typename
            Show_Contact_Form
            Title
            Content
            May_Also_CTA_Buttons {
              CTA_Label
              CTA_Icon {
                url
                alternativeText
              }
              CTA_Link
              Icon_ClassName
            }
          }
          ... on ComponentComponentReviewsModule {
            id
            __typename
            Heading
            Review_content {
              Review_content
              Review_name
            }
          } 
          
        }

        What_We_Do {
          Content
          Title
          id
          Links {
            Label
            id
            Link {
              Primary_URL
              Label
              Image {
                url
                alternativeText
              }
              Main_Parent {
                Label
                Primary_URL
              }
              Secondary_Menu {
                Label
                Primary_URL
              }
              nested_menus {
                Primary_URL
              }
              
            }
          }
        }
        meet_our_teams {
          id
          Name
          URL
          Image {
            alternativeText
            url
          }
          Phone_Number
          Email_ID
          Form_Queue_ID
          Departments
          Level
          imagetransforms
          ggfx_results {
            id
            content_type
            transforms
            src_import_url
            src_cftle
            field
          }
          people_department{
            id
            Name
          }
          people_departments{
            id
            Name
          }
          people_services{
            id
            Name
          }
        }
    }
}`

/* ========== All Clients ======== */
const All_Clients = gql`
query GetClients{
  clients(sort: "createdAt:desc") {
    Name
    Link
    Image_or_Logo {
      url
      alternativeText
    }
  }
}`

/* ========== All Vacancies ======== */
const Vacancies = gql`
query GetVacancies{
  vacancies(sort: "createdAt:desc"){
    Title
    id
    URL
    Image {
      alternativeText
      url
    }
    imagetransforms
    ggfx_results {
      id
      content_type
      transforms
      src_import_url
      src_cftle
      field
    }
  }
}`

/* ========== Vacancy Details ======== */
const VacancyDetails = gql`
query VacancyDetails($slug: String!){
  vacancies(where: {URL:$slug}){
    id
    Title
    URL
    Service
    Subscribe
    Deparment
    Content
    About_Strettons
    people {
      id
      Name
      Email_ID
      URL
      Phone_Number
      Departments
      Level
      people_department{
        id
        Name
      }
      people_departments{
        id
        Name
      }
      people_services{
        id
        Name
      }
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Image {
        alternativeText
        url
      }
    }
    office {
      Name
    }
    Image {
      alternativeText
      url
    }
    imagetransforms
    ggfx_results {
      id
      content_type
      transforms
      src_import_url
      src_cftle
      field
    }
  }
}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{status:$status, department:$department, publish:true}) {
        id
        display_address
        address
        search_type
        status
        slug
        title
        search_type
        department
        building
        price
        images
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        floorarea_min
        floorarea_max
        floorarea_type
        introtext
        crm_negotiator_id
        extra
        latitude
        longitude
        auctionDate
    }

}`

const NoResultPropertiesAuctions = gql`
query NoResultPropertiesAuctions($department: String!){
    properties(limit:6, where:{department:$department, status_in:["Unsold", "ReferToAuctioneer"], publish:true}) {
        id
        display_address
        address
        search_type
        status
        slug
        title
        search_type
        department
        building
        price
        images
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        floorarea_min
        floorarea_max
        floorarea_type
        crm_negotiator_id
        introtext
        extra
        latitude
        longitude
        auctionDate
        auctionId
    }

}`

const AuctionIds = gql`
query AuctionIds{
  auctions(limit:2, where: {auctionType: "past"}, sort: "auctionDate:desc") {
    id
    crm_id
    auctionType
  }
}`

const GetAuctionProperties = gql`
query GetAuctionProperties($auctionId1: String!, $auctionId2: String!, $auction_residential: String!, $auction_commercial: String!){
    properties(limit:25, where:{auctionId_in:[$auctionId1,$auctionId2], department_in:[$auction_residential,$auction_commercial], status_in:["Unsold", "ReferToAuctioneer"], publish:true}) {
        id
        display_address
        address
        search_type
        status
        slug
        title
        search_type
        department
        building
        price
        images
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        floorarea_min
        floorarea_max
        floorarea_type
        crm_negotiator_id
        introtext
        extra
        latitude
        longitude
        auctionDate
        auctionId
    }

}`

/* ========== Featured Properties ======== */
const FeaturedProperties = gql`
query FeaturedProperties($status: String!, $department: String!){
    properties(limit:6, where:{status:$status, department:$department, publish:true}) {
        id
        display_address
        address
        search_type
        status
        slug
        title
        search_type
        department
        building
        price
        images
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
        floorarea_min
        floorarea_max
        floorarea_type
        crm_negotiator_id
        introtext
        extra
        price_qualifier
        
    }

}`

/* ========== Similar Properties ======== */
const SimilarPropertiesAuctions = gql`
query SimilarPropertiesAuctions($area: String!, $department: String!, $auctionType: String!){
  refer_auctions:properties(limit:4, where:{display_address_contains:$area, department:$department, auctionType:$auctionType, publish:true}, sort: "auctionDate:desc") {
    id
    display_address
    search_type
    status
    slug
    title
    search_type
    department
    building
    price
    images
    imagetransforms
    ggfx_results {
      id
      content_type
      transforms
      src_import_url
      src_cftle
      field
    }
    floorarea_min
    floorarea_max
    floorarea_type
    crm_negotiator_id
    extra
    auctionDate
    auctionType
  }
}`

/* ========== Future Auction List ======== */
const FuturAuctionList = gql`
query FuturAuctionList($status: String!){  
  auctions(limit:8, where:{auctionType: $status}, sort:"auctionDate:asc") {
    id
    crm_id
    publish
    auctionDate
    auctionType
    lotsOffered
    percentageSold
    totalRaised
    thumbnail
    Image{
      alternativeText
      url
    }
  }
}`

/* ========== Past Auction List ======== */
const PastAuctionList = gql`
query PastAuctionList($status: String!){  
  auctions(limit:8, where:{auctionType: $status, publish:true}, sort:"auctionDate:desc") {
    id
    crm_id
    publish
    auctionDate
    auctionType
    lotsOffered
    percentageSold
    totalRaised
    thumbnail
    Image{
      alternativeText
      url
    }
  }
}`

/* ========== Auction List ======== */
const AllAuctions = gql`
query AllAuctions($status: String!, $sort_order: String!){  
  auctions(limit:12, where:{auctionType: $status}, sort:$sort_order) {
    id
    crm_id
    publish
    auctionDate
    auctionType
    lotsOffered
    percentageSold
    totalRaised
    thumbnail
    Image{
      alternativeText
      url
    }
  }
}`

/* ========== Auction List ======== */
const AuctionsPropertyPage = gql`
query AuctionsPropertyPage{  
  articles(where:{Alias: "auction-property-search"}) {
    id
    Pagename
    Alias
    Layout

    Modules {
      __typename

      ...on ComponentComponentBannerBottom{
        Banner_Bottom_Module {
          id
          Link
          Heading
          Content
        }
      }
    }  
    
  }
}`

/* ========== PopularSearch ======== */
const PopularSearch = gql`
query PopularSearch($slug: String!){  
  allMenus(where:{Primary_URL:$slug}){
    Label
    popular_searches{
      id
      Name
      Content
    }
    Modules {
      __typename
      ... on ComponentComponentPopularSearchComponent {
        id
        Popular_Search_Module {
          Sort
          popular_search {
            Content
            Name
          }
        }
      }
    }
  }
}`

/* ========== AllProperties ======== */
const AllProperties = gql`
query AllProperties($department: String!){  
  properties(sort:"auctionDate:desc", where:{department:$department, status_nin:["Withdrawn"], publish:true}){
    id
    display_address
    slug
  }
}`

/* ========== GetPropByCRMID ======== */
const GetPropByCRM = gql`
query GetPropByCRM($crm_id: ID!){  
  properties(where:{crm_id:$crm_id, publish:true}){
    id
    crm_id
    display_address
    slug
    department
  }
}`


/* ========== All Departments ======== */
const AllDepartments = gql`
query AllDepartments{  
  peopleDepartments(sort:"Name:asc"){
    id
    Name
    news_and_insights{
      id
      
      news_and_insights_category{
        Name
      }
    }
  }
}`

/* ========== All Departments - Insights ======== */
const AllDepartmentsInsights = gql`
query AllDepartmentsInsights{  
  peopleDepartments(sort:"Sort:asc"){
    id
    Name
    news_and_insights{
      id
      
      news_and_insights_category{
        Name
      }
    }
  }
}`

/* ========== Categories - Insights ======== */
const NewsAndInsight = gql`
query NewsAndInsight ($category_name: String!){
  newsAndInsights(where: {news_and_insights_category: {Name: $category_name}},sort:"Name:asc") {
    id
    news_and_insights_category {
      Name
    }
    Title
    insight_departments {
      Name
    }
  }
}`

/* ========== All Categories - Insights ======== */
const AllNewsAndInsightsCategories = gql`
query AllNewsAndInsightsCategories{
  newsAndInsights(sort:"Name:asc") {
    id
    news_and_insights_category {
      Name
    }
    Title
    insight_departments {
      Name
    }
  }
}`

// /* ========== All Services ======== */
const AllServices = gql`
query AllServices{
  peopleServices(sort:"Name:asc"){
    id
    Name
    people_department{
      id
      Name
    }
  }
}`

export const Queries = {
    Insights,
    InsightsFilters,
    People_Team,
    People_Email,
    People_NegoID,
    People_Filter,
    People_Details,
    GetOffices,
    Office_Details,
    All_Clients,
    Insight_Details,
    FeatureInsights,
    RelatedInsights,
    Home_People,
    Vacancies,
    VacancyDetails,
    NoResultProperties,
    NoResultPropertiesAuctions,
    FeaturedProperties,
    FuturAuctionList,
    PastAuctionList,
    AllAuctions,
    AuctionsPropertyPage,
    PopularSearch,
    SimilarPropertiesAuctions,
    AllProperties,
    AllDepartments,
    AllServices,
    AllDepartmentsInsights,
    GetPropByCRM,
    AllNewsAndInsightsCategories,
    NewsAndInsight,
    AuctionIds,
    GetAuctionProperties
    //Clients
};

